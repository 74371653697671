import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Environment = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Environment;
    return <Component {...props} />;
  },
});

const Level = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Level;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/level.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{
          React: React,
          Environment: Environment,
          Level: Level,
          Text: Text,
        }}
        code={snippet}
        platform="react"
        gitHubLink="themes/level"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>The content to apply the level to.</Text>
          </PropListItem>

          <ClassnamePartial componentName="level" />

          <PropListItem name="level" types={['string']}>
            <Text>
              Determines which of the four levels to apply as a background
              color.
            </Text>
            <List type="unordered">
              <li>
                <code>0</code> (default)
              </li>
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="type" types={['string']}>
            <Text>
              Determines which of the two available types of background color is
              applied.
            </Text>
            <List type="unordered">
              <li>
                <code>base</code> (default)
              </li>
              <li>
                <code>accent</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Miscallaneous">
          <QaIdPartial componentName="level" />
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            This component must be used in conjunction with the{' '}
            <Link href="/components/themes/environment" isDesignCodeLink>
              Environment
            </Link>{' '}
            component in order to work.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
